import React from 'react';
import { nameInitials } from '../../../utils/functions';
import { defaultConfigToApply } from '../../../utils/randomPhoto';
import { styleImg, styleNameAvatar } from '../styles';

interface Props {
  name: string;
  imgAvatar: string;
}
export const AvatarCurrentUser = ({ name, imgAvatar }: Props) => {
  const nameInitial = nameInitials(name);
  const img = `${process.env.FTP_IMAGENS}${imgAvatar}`;
  const avatarImg = <img src={img} alt="Foto de perfil do usuário" className={styleImg} />;
  const avatarRandom = (
    <div className={styleNameAvatar} style={{ backgroundColor: defaultConfigToApply.bgColor }}>
      <p className="white">{nameInitial}</p>
    </div>
  );

  return !imgAvatar ? avatarRandom : avatarImg;
};
