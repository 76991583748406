import cx from 'classnames';
import styles from './style.module.css';

export const styleSection = (classSection: any) =>
  cx(
    'bn ph16 pb16 ph0-ns pb0-ns flex flex-wrap inline-flex-ns w-100 items-baseline items-center-m items-center-l items-center-ns flex-column flex-row-ns flex-row-m flex-row-l justify-between-ns justify-between-m justify-between-l bg-abbey',
    styles.section,
    classSection,
  );
export const styleSubtitle = cx('white flex-nowrap f13 fw4 mt0 mb12 mb0-ns', styles.subtitle);
export const styleButton = cx('white bg-transparent mv12-ns', styles.button);
export const styleImg = cx(
  'h2 w2 mt16 mv8-ns  ml24-ns ml32-l mr12 mr8-l br-100',
  styles.profilePic,
);
export const styleNameAvatar = cx(
  'h2 w2 mt16 mv8-ns  ml24-ns ml32-l mr12 mr8-l flex justify-center items-center overflow-hidden b--white ba cover br-100',
  styles.profilePic,
);
export const styleWrapperWarn = cx('w-100 flex justify-start', styles.wrapperWarn);
