import React from 'react';
import { StepFour } from '../StepFour';
import { StepOne } from '../StepOne';
import { StepThree } from '../StepThree';
import { StepTwo } from '../StepTwo';
import { StepVehiclesProps } from '../utils/Type';

export const StepVehicles = ({
  stepConfig,
  car,
  optionals,
  ad,
  photos,
  isLoading = false,
}: StepVehiclesProps) => {
  return (
    <>
      <StepOne {...stepConfig} defaultValues={car} isLoading={isLoading} />
      <StepTwo {...stepConfig} defaultValues={optionals} isLoading={isLoading} />
      <StepThree {...stepConfig} values={ad} isLoading={isLoading} />
      <StepFour {...stepConfig} gallery={photos} isLoading={isLoading} />
    </>
  );
};
