import React, { Component } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { Link } from 'gatsby';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import { inject, observer } from 'mobx-react';
import { ContainerRegisterVehicle } from '../../components/Container';
import { LoaderFullPage } from '../../components/Loaders';
import { ModalSuccessAndError } from '../../components/ModalSuccessAndError';
import { NotifyCurrentUser } from '../../components/NotifyCurrentUser';
import { Topbar } from '../../components/Topbar';
import { toFloat } from '../../utils/functions';
import { PARTS_NUMBER, CAR_NUMBER } from '../../utils/productType';
import { convertAd, convertPhotos, clearPartsPhotos } from '../../utils/treatData';
import { TypeUserStore } from '../Login/components/SignIn/store/user';
import { TypeClientStore } from '../SubscriptionPlans/store/selectedStore';
import { advertisement } from './services/advertisement';
import { StepFive } from './StepFive';
import { StepParts } from './StepParts';
import { StepVehicles } from './StepVehicles';
import styles from './styles.module.css';
import { initialStateVehicleRegistration } from './utils/Emptys';
import { formatAd, treatPath, vehicleTypeNameByNumber, formatAdParts } from './utils/functions';
import { stepKeys, DRAFT, ACTIVE } from './utils/functions';
import { VehicleRegistrationStates, Values, Photo } from './utils/Type';

interface Props {
  user: TypeUserStore;
  registration?: any;
  selectedStore: TypeClientStore;
}

@inject('registration')
@inject('selectedStore')
@inject('user')
@observer
export class VehicleRegistration extends Component<Props, VehicleRegistrationStates> {
  state: VehicleRegistrationStates = {
    adId: -1,
    success: true,
    loading: false,
    openModal: false,
    stepActive: 0,
    previousStep: 1,
    loaderFull: true,
    urlId: '',
    text: 'Obrigado.',
    title: 'Parabéns',
    subtitle: 'Veículo cadastrado com sucesso',
    planStatus: '',
    vehicleType: CAR_NUMBER,
    values: cloneDeep(initialStateVehicleRegistration),
  };

  componentWillMount = async () => {
    const path = treatPath(window.location.pathname);
    if (!path.type) {
      return navigate('selecionar-tipo-de-anuncio');
    }

    this.setState({ vehicleType: path.type });

    if (path.id) {
      if (path.type === 8) {
        this.setState({ urlId: path.id, loaderFull: true });
        await advertisement
          .getAdParts(path.id)
          .then(res => {
            if (res) {
              const data = formatAdParts(res.data);
              this.setState({ stepActive: 1, values: data });
            }
          })
          .catch(() => {
            this.setState({ loading: false });
            this.openModal('Anúncio não disponível.', true);
          });
        this.setState({ loaderFull: false });
        return null;
      }
      this.setState({ urlId: path.id, loaderFull: true });
      await advertisement
        .getAd(path.id)
        .then(res => {
          if (res) {
            const { photoUrl, price, optionals } = res.data;
            const data = formatAd(res.data);
            if (photoUrl && photoUrl.length) {
              this.setState({ stepActive: 3 });
            } else if (price) {
              this.setState({ stepActive: 2 });
            } else if (optionals && optionals.length && optionals[0] !== '') {
              this.setState({ stepActive: 1 });
            }
            this.setState({ values: data });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
          this.openModal('Anúncio não disponível.', true);
        });
      this.setState({ loaderFull: false });
    }
    this.setState({ loaderFull: false });
  };

  openModal = (message?: string, isError = false) => {
    const sucessConfig = {
      title: 'Parabéns',
      text: 'Obrigado.',
      success: true,
      openModal: true,
    };
    const errorConfig = {
      title: 'Ops',
      text: 'Tente mais tarde',
      success: false,
      openModal: true,
    };

    if (isError) {
      this.setState({
        ...errorConfig,
        subtitle: message ? message : 'Houve um erro na execução',
      });
    } else {
      this.setState({
        ...sucessConfig,
        subtitle: message ? message : 'Veículo cadastrado com sucesso',
      });
    }
  };

  setValues = (step: number, payload?: any) => {
    const { values } = this.state;
    const key: keyof Values = stepKeys[step - 1];
    values[key] = payload;
    const payloadFormatted = convertAd(values, DRAFT);

    return payloadFormatted;
  };

  handlePartsPhotos = (photos: Photo[]) => {
    let clearedPhotos = clone(photos);
    clearedPhotos = clearPartsPhotos(photos);
    clearedPhotos = convertPhotos(clearedPhotos);
    return clearedPhotos;
  };

  getProductId = () => {
    if (typeof window === 'undefined') {
      return 0;
    }
    const productId = Number(window.location?.pathname?.split('/').pop());
    return productId || 0;
  };

  handleSubmitParts = async () => {
    const { values } = this.state;
    const { Parts } = values;
    this.setState({ loaderFull: false });
    const payload = clone(Parts);

    payload.photos = this.handlePartsPhotos(payload.photos);
    payload.price = toFloat(payload.price);

    if (this.props.selectedStore) {
      const { clientStore } = this.props.selectedStore;
      payload.userId = clientStore.id;
    }
    const productId = this.getProductId();
    const isAnExistingDraft = !!productId;
    payload.modelType = payload.modelType.toString();
    if (isAnExistingDraft) {
      payload.userId =
        productId; /* Por alguma razão, o serviço trata o ID do produto como ID do usuário */
    }
    const advertisementService = isAnExistingDraft
      ? advertisement.updateParts
      : advertisement.saveParts;

    if (payload.status === DRAFT) {
      this.setState({ loaderFull: true });

      await advertisementService(payload)
        .then(() => navigate('/meus-anuncios/pecas'))
        .catch((res: any) => {
          this.setState({ loaderFull: false });
          if (res.message) {
            this.openModal(res.message, true);
          } else {
            this.openModal('Ocorreu um erro ao salvar seu produto', true);
          }
        });
    }
    this.setState({ loaderFull: true });
    await advertisementService(payload)
      .then(res => {
        const { data } = res;
        this.setState({ adId: data.advId, planStatus: data.cantActivateReason });
        this.openModal(data.message);
        this.setState({ loaderFull: false });
      })
      .catch((res: any) => {
        this.setState({ loaderFull: false });
        if (res.message) {
          this.openModal(res.message, true);
        } else {
          this.openModal('Ocorreu um erro ao salvar seu produto', true);
        }
      });
  };
  categoryRedirect = () => vehicleTypeNameByNumber(this.state.vehicleType);
  nextStep = (step: number, payload?: any, status?: any) => {
    const { values, stepActive, previousStep, urlId, vehicleType } = this.state;
    const { registerVehicle } = this.props.registration;
    const { clientStore } = this.props.selectedStore;
    const { profile } = this.props.user;
    if (payload) {
      const payloadFormatted = this.setValues(step, payload);

      if (status === DRAFT) {
        this.setState({ loaderFull: true });
        registerVehicle(payloadFormatted, urlId, {
          userId: clientStore.id || profile.id,
          type: vehicleType,
        })
          .then(() => navigate(`/meus-anuncios/${this.categoryRedirect()}`))
          .catch((res: any) => {
            this.setState({ loaderFull: false });
            if (res.message) {
              this.openModal(res.message, true);
            } else {
              this.openModal('Ocorreu um erro ao salvar seu anuncio', true);
            }
          });
      }
      this.setState({ values });
    }
    if (status !== DRAFT) {
      const newStepActive = stepActive < previousStep ? previousStep : step;
      this.setState({
        stepActive: newStepActive,
        previousStep: stepActive,
      });
    }
  };

  handleSubmit = async (status?: number) => {
    const { id } = this.props.selectedStore.clientStore;
    const { vehicleType } = this.state;
    const { registerVehicle } = this.props.registration;

    if (vehicleType === PARTS_NUMBER) {
      return this.handleSubmitParts();
    }

    if (status === DRAFT) {
      this.setState({ loaderFull: true });
    } else {
      this.setState({ loading: true });
    }
    const { values, urlId } = this.state;
    const payload = convertAd(values, status);
    registerVehicle(payload, urlId, {
      userId: id ? id : null,
      type: vehicleType,
    })
      .then((res: any) => {
        this.setState({ loaderFull: false });
        if (status === ACTIVE) {
          this.setState({ adId: res.data.advId, planStatus: res.data.cantActivateReason });
          return this.openModal();
        }
        navigate(`/meus-anuncios/${this.categoryRedirect()}`);
      })
      .catch(() => {
        this.setState({ loaderFull: false });
        const type = status === DRAFT ? 'salvar' : 'criar';
        return this.openModal(`Ocorreu um erro ao ${type} seu anúncio`, true);
      });
  };

  closeModal = () => {
    const { openModal, adId, planStatus, success, vehicleType } = this.state;
    if (success) {
      this.setState({ values: cloneDeep(initialStateVehicleRegistration) });
      if (planStatus === 'NO_ACTIVE_PLAN') {
        return vehicleType === PARTS_NUMBER
          ? navigate(`/planos/pecas/${adId}`)
          : navigate(`/planos/${adId}`);
      }
      return navigate(`/meus-anuncios/${this.categoryRedirect()}`);
    }
    this.setState({ loading: false, openModal: !openModal });
  };

  render() {
    if (this.state.loaderFull && !this.state.loading) {
      return <LoaderFullPage />;
    }
    const styleText = cx('boulder f11 lh-paragraph tc ph16 pb24 pt32', styles.text);
    const {
      stepActive,
      title,
      subtitle,
      text,
      loading,
      openModal,
      success,
      values,
      urlId,
      loaderFull,
      vehicleType,
    } = this.state;

    const { Ad, Car, Optionals, Gallery, Parts } = values;

    const modelId = Car && Car.modelId;

    const { nextStep, handleSubmit, props, setValues } = this;
    const stepConfig = {
      nextStep,
      stepActive,
      urlId,
      modelId,
      loading,
      values,
      handleSubmit,
      vehicleType,
    };

    const renderSteps = () =>
      vehicleType === PARTS_NUMBER ? (
        <StepParts {...stepConfig} defaultValues={Parts} updateValues={setValues} />
      ) : (
        <StepVehicles
          stepConfig={stepConfig}
          car={Car}
          optionals={Optionals}
          ad={Ad}
          photos={Gallery.photos}
        />
      );

    const titleHeader = vehicleType === PARTS_NUMBER ? 'Cadastro de peças' : 'Cadastro de veículos';

    return (
      <>
        <LoaderFullPage loading={loaderFull && !loading} />
        <Topbar />
        <NotifyCurrentUser {...props} />
        <ContainerRegisterVehicle>
          <h1 className="f20 f24-ns f30-m f30-l fw7 abbey tc mb32 mt32 ttu">{titleHeader}</h1>
          {renderSteps()}
          <StepFive {...stepConfig} {...props} />
          <ModalSuccessAndError
            text={text}
            title={title}
            isOpen={openModal}
            success={success}
            subTitle={subtitle}
            closeModal={this.closeModal}
            textBtnClose="Ok"
          />
          <p className={styleText}>
            Ao anunciar na Autoscar você concorda com a nossa
            <Link to="" className="boulder mh4">
              Política de Privacidade
            </Link>
            <Link to="" className="boulder">
              Publicidade
            </Link>
            , além de se responsabilizar por quaisquer informações anunciadas.
          </p>
        </ContainerRegisterVehicle>
      </>
    );
  }
}
