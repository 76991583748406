import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import Measure from 'react-measure';
import styles from './AdStrength.module.css';

interface ReactContent {
  bounds: {
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

interface Improve {
  buttonText: string;
  onClick?: () => void;
  improve?: boolean;
}
interface Props {
  id?: string;
  improvements: Improve[];
}

export const AdStrength = ({ improvements }: Props) => {
  const [strength, setStrength] = useState(0);
  useEffect(() => {
    let acumulador = 0;
    map(improvements, improvement => {
      if (improvement.improve) {
        acumulador += 1;
      }
    });
    setStrength(acumulador);
  }, []);
  const strengthOptions = {
    percentages: [1, 0.5, 0.25, 0],
    strengthLevel: ['Muito bom', 'Bom', 'Fraco', 'Ruim'],
    textSizes: [80, 30, 20, 0],
    strengthColor: ['#1bdb10', '#ff8001', '#ff8001', '#ff0000'],
  };
  const [delayStrength, setDelayStrength] = useState(3);
  const [percentage, setPercentage] = useState(0);
  const [dimensions, setDimensions] = useState({ width: -1, height: -1 });
  const resize = (e: ReactContent) => {
    setDimensions(e.bounds);
  };
  setTimeout(() => {
    setPercentage(strengthOptions.percentages[delayStrength]);
    setDelayStrength(strength);
  }, 100);
  const slider = cx('z-0 db br3 relative ', styles.slider);
  const improvementsClass = cx(
    'flex w-100 bg-white br3 justify-between ph8 pv12 mb12 mb8-ns',
    styles.item,
    styles.greyRGBA,
  );
  const buttonClass = cx('pointer bg-white bn ttu f12 fw7', styles.greyRGBA);
  const mapImprovements = (improv: Improve[]) => {
    return improv.map((improvement, key) => {
      if (improvement.improve) {
        return (
          <div className={improvementsClass} key={key}>
            <span className="f14 fw3">{improvement.buttonText}</span>
            <button className={buttonClass} onClick={improvement.onClick}>
              + Adicionar
            </button>
          </div>
        );
      }
    });
  };
  const congrats = (
    <span className={cx('o-0 db ttu mt16 mt24-ns f16 relative', styles.congrats, styles.greyRGBA)}>
      <p className="ma0 fw2">Parabéns.</p>
      <p className="ma0 b fw6">Seu anúncio está ótimo!</p>
    </span>
  );
  const improvementQuestion = (
    <p className={cx('b mb8 mt16 mt24-ns f14', styles.greyRGBA)}>QUER MELHORAR SEU ANÚNCIO?</p>
  );
  const circleStyle = {
    left: `${16 + (dimensions.width - 48) * percentage}px`,
    backgroundColor: strengthOptions.strengthColor[delayStrength],
  };
  const lineStyle = {
    width: `${(dimensions.width - 32) * percentage}px`,
  };
  const textAlign = {
    paddingLeft: `${(dimensions.width - strengthOptions.textSizes[strength]) * percentage}px`,
  };
  const circleClass = cx('absolute db z-1 br-100', styles.circleSlider);
  const lineClass = cx('absolute db z-0 o-50', styles.lineSlider);
  const textClass = cx('o-0 db ma0 b f14 fw7 mt8', styles.textLabelAnimation);
  const measuredComponent = ({ measureRef }: any) => (
    <section className="abbey">
      <p className="fw7 b ma0 mb12 mb24-ns pl0 f18 f24-ns">Força do seu anúncio</p>
      <div>
        <span ref={measureRef} className={slider}>
          <div className={circleClass} style={circleStyle} />
          <div className={lineClass} style={lineStyle} />
        </span>
        <p style={textAlign} className={textClass}>
          {strengthOptions.strengthLevel[strength]}
        </p>
      </div>
      {!!strength && improvementQuestion}
      <div>
        {!!strength && mapImprovements(improvements)}
        {!strength && congrats}
      </div>
    </section>
  );
  return (
    <Measure bounds onResize={resize}>
      {measuredComponent}
    </Measure>
  );
};
