import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { AdStrength } from '../../../components/AdStrength';
import { PrimaryButton } from '../../../components/Button/Primary';
import { CardAd } from '../../../components/CardAd';
import { Link } from '../../../components/Link';
import { Step } from '../../../components/Step';
import { TypeUserStore } from '../../Login/components/SignIn/store/user';
import { TypeClientStore } from '../../SubscriptionPlans/store/selectedStore';
import { ACTIVE, DRAFT } from '../utils/functions';
import styles from './styles.module.css';

interface Props {
  stepActive: number;
  nextStep: (id: number) => void;
  values: any;
  handleSubmit: (status?: number) => void;
  loading: boolean;
  selectedStore: TypeClientStore;
  user: TypeUserStore;
}

interface Photo {
  id: string;
  url: string;
  principal: boolean;
  base64: string;
  type: number;
}

export const StepFive = inject(
  'user',
  'selectedStore',
)(
  observer(
    ({ user, selectedStore, stepActive, nextStep, values, handleSubmit, loading }: Props) => {
      const { clientStore } = selectedStore;
      const { profile } = user;
      const clientName = get(
        profile.admin ? clientStore : profile,
        (profile.admin ? clientStore.type : profile.type) === 1 ? 'name' : 'fantasyName',
        '',
      );

      const { Gallery, Car } = values;
      let urlPictures: any[] = [];
      if (stepActive < 4 && (!Gallery || (Gallery.photos64 && !Gallery.photos64.length))) {
        return null;
      }
      const onClick = () => {
        nextStep(5);
      };
      if (values && Gallery.photos) {
        urlPictures = Gallery.photos.filter((item: any) => item.url || item.base64);
        urlPictures = urlPictures.map((item: Photo) => {
          const { url, base64, type } = item;
          const newUrl = type === 2 ? url.replace(`${process.env.FTP_IMAGENS}`, '') : url;
          return type === 1 ? base64 : newUrl;
        });
      }
      const { description, kilometers, price } = get(values, 'Ad', {
        kilometers: 0,
        description: '',
        price: '0',
      });
      const { modelYear, fabricationYear, brandName, name, version } = Car;
      const improvements = [
        {
          buttonText: 'Adicione mais fotos',
          onClick: () => nextStep(3),
          improve: urlPictures.length < 5,
        },
        {
          buttonText: 'Adicione quilometragem',
          onClick: () => nextStep(2),
          improve: !kilometers,
        },
        {
          buttonText: 'Adicione descrição',
          onClick: () => nextStep(2),
          improve: !description,
        },
      ];
      const classCard = cx(
        'mb24 mb0-ns mr24-ns mr40-m flex justify-center items-start center pb24-m',
        styles.cardAd,
      );

      return (
        <Step
          isOpen={stepActive === 4}
          isDone={false}
          onChange={onClick}
          stepNumber="ETAPA 5"
          stepName="Publicação"
          stepDescription="Confira o seu anúncio e se estiver tudo certo, é só publicar."
        >
          <div className="mt12 pb24 pb12-ns pb16-m mt16-m flex-ns">
            <div className={classCard}>
              <CardAd
                urlPictures={urlPictures}
                carName={`${brandName} ${name} ${version || ''}`}
                modelYear={modelYear}
                fabricationYear={fabricationYear}
                km={kilometers}
                price={price}
                clientName={clientName}
              />
            </div>
            <div className="w-100 mb24 mb0-ns flex-ns flex-column justify-between pr0-ns pr0-m pr40-l pb24-m">
              <AdStrength improvements={improvements} />
              <div className="mt24 mt32-ns">
                <PrimaryButton
                  aria-label="Botao Publicar"
                  // tslint:disable-next-line: jsx-no-lambda
                  onClick={() => handleSubmit(ACTIVE)}
                  loading={loading}
                >
                  Publicar
                </PrimaryButton>
                <div className="tc pb12 pb24-ns mt32 ttu">
                  <button
                    type="submit"
                    className="bg-transparent bn"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => handleSubmit(DRAFT)}
                  >
                    <Link aria-label="Botao Salvar e Sair" className="f14 fw6">
                      SALVAR E SAIR
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Step>
      );
    },
  ),
);
