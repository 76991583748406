import React from 'react';
import { navigate } from '@reach/router';
import { inject, observer } from 'mobx-react';
import { UserData } from '../../modules/Login/components/SignIn/store/user';
import { StoreData } from '../../modules/SubscriptionPlans/store/selectedStore';
import { OutlineButton } from '../Button';
import { AvatarCurrentUser } from './components/AvatarCurrentUser';
import { styleSection, styleWrapperWarn, styleSubtitle, styleButton } from './styles';

interface SelectedStore {
  clientStore: StoreData;
}
interface UserStore {
  profile: UserData;
}
interface Props {
  title?: string;
  subtitle?: string;
  textButton?: string;
  userName?: string;
  classSection?: any;
  urlPhoto?: string;
  user: UserStore;
  selectedStore: SelectedStore;
}

export const NotifyCurrentUser = inject(
  'user',
  'selectedStore',
)(
  observer(
    ({
      user,
      selectedStore,
      title = 'Atenção!',
      textButton = 'TROCAR USUÁRIO',
      classSection,
    }: Props) => {
      const { profile } = user;
      const { admin } = profile;
      const { clientStore } = selectedStore;
      const { aliasName, urlCompanyLogo } = clientStore;

      if (!admin) {
        return null;
      }

      const redirect = () => {
        navigate('/gerenciamento-usuarios');
      };

      return (
        <section className={styleSection(classSection)}>
          <span className="flex justify-center justify-start-ns">
            <span className={styleWrapperWarn}>
              <AvatarCurrentUser name={aliasName} imgAvatar={urlCompanyLogo} />
              <div className="w-75  w-100-ns">
                <h3 className="white f14 lh-copy fw6-l mb0 mt12">{title}</h3>
                <p className={styleSubtitle}>Você está editando como {aliasName}.</p>
              </div>
            </span>
          </span>
          <div className="flex justify-center justify-end-nsh-100">
            <OutlineButton size="medium" className={styleButton} onClick={redirect}>
              {textButton}
            </OutlineButton>
          </div>
        </section>
      );
    },
  ),
);
